import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  FaFacebookF,
  FaTwitter,
  FaPinterestP,
  FaLinkedin,
  FaInstagram,
  FaVimeoV,
  FaDribbble,
  FaBehance,
  FaEnvelopeOpen,
} from "react-icons/fa";
import ServiceData from "../../data/service/ServiceMain.json";
import { slugify } from "../../utils";
import Alert from 'react-bootstrap/Alert';

const getServiceData = ServiceData;
const API_TOKEN = 'xdUUxPjkE0irVjXHQWy1eDRnOidsG1967ZyPzgjkikn2cY6bbGkpuAGkjPfH';
const LIST_UID = '64359259659e5';

const Result = () => {
  return (
      <Alert variant="success" className="success-msg">
      Thanks for subscribing.
      </Alert>
  )
}

const FooterOne = ({ parentClass }) => {

  const [ result, showresult ] = useState(false);

  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [tags, setTags] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: `api_token=${API_TOKEN}&list_uid=${LIST_UID}&EMAIL=${email}&FIRST_NAME=${firstName}&LAST_NAME=${lastName}&tag=${tags}`
    };

    fetch('https://app.emailhup.com/api/v1/subscribers', requestOptions)
    .then(response => {
      if (response.status === 200) {
        return response.json();
      } else {
        throw new Error('Failed to subscribe.');
      }
    })
    .then(data => {
      if (data.status == 1) {
        alert('Thanks for subscribing!');
        // reset the form
    // setEmail('');
    // setFirstName('');
    // setLastName('');
    // setTags('');
        console.log('Subscriber UID:', data.subscriber_uid);
      } else {
        throw new Error('Failed to subscribe: ' + data.message);
      }
    })
    .catch(error => {
      // alert('Thanks for subscribing. If successful, we would send you a message.');
      showresult(true)
      setEmail('');
    });


          // reset the form
    setEmail('');
    setFirstName('');
    setLastName('');
    setTags('');

    setTimeout(() => {
      showresult(false);
  }, 5000);

  };


  return (
    <footer className={`footer-area ${parentClass}`}>
      <div className="container">
        <div className="footer-top">
          <div className="footer-social-link">
            <ul className="list-unstyled">
              <Link to="https://www.facebook.com/people/Visoan/100088826931628/"></Link>
              <li>
                <a href="">
                  <FaFacebookF />
                </a>
              </li>
              <li>
                <a href="https://twitter.com/Visoanbyoan" target="_blank">
                  <FaTwitter />
                </a>
              </li>
              <li>
                <a to="https://www.instagram.com/visoanbyoan/" target="_blank">
                  <FaPinterestP />
                </a>
              </li>
              <li>
                <a
                  href="https://www.linkedin.com/showcase/91650135/admin/"
                  target="_blank"
                >
                  <FaLinkedin />
                </a>
              </li>
              <li>
                <a
                  href="https://www.instagram.com/visoanbyoan/"
                  target="_blank"
                >
                  <FaInstagram />
                </a>
              </li>
              <li>
                <Link to="https://vimeo.com/">
                  <FaVimeoV />
                </Link>
              </li>
              <li>
                <Link to="https://dribbble.com/">
                  <FaDribbble />
                </Link>
              </li>
              <li>
                <Link to="https://www.behance.net/">
                  <FaBehance />
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="footer-main">
          <div className="row">
            <div className="col-xl-6 col-lg-5">
              <div className="footer-widget border-end">
                <div className="footer-newsletter">
                  <h2 className="title">Get in touch!</h2>
                  <p>
                    At our digital agency, we are always ready to help you
                    achieve your digital marketing goals. If you have any
                    questions or are interested in working with us, please fill
                    out our "Get in touch" form below
                  </p>
                  <form onSubmit={handleSubmit}>
                    <div className="input-group">
                      <span className="mail-icon">
                        <FaEnvelopeOpen />{" "}
                      </span>
                      <input
                        type="email"
                        value={email}
                        onChange={(event) => setEmail(event.target.value)}
                        className="form-control"
                        placeholder="Email address"
                      />
                      <button className="subscribe-btn" type="submit">
                        Subscribe
                      </button>
                    </div>
                    <div className="form-group">
                    {result ? <Result /> : null}
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-7">
              <div className="row">
                <div className="col-sm-6">
                  <div className="footer-widget">
                    <h6 className="widget-title">Services</h6>
                    <div className="footer-menu-link">
                      <ul className="list-unstyled">
                        {getServiceData.slice(0, 6).map((data, index) => (
                          <li key={index}>
                            <Link
                              to={
                                process.env.PUBLIC_URL +
                                `/service-details/${slugify(data.title)}`
                              }
                            >
                              {data.title}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-sm-3">
                  <div className="footer-widget">
                    <h6 className="widget-title">Resourses</h6>
                    <div className="footer-menu-link">
                      <ul className="list-unstyled">
                        <li>
                          <Link to={process.env.PUBLIC_URL + "/blog-grid"}>
                            Blog
                          </Link>
                        </li>
                        <li>
                          {" "}
                          <Link to={process.env.PUBLIC_URL + "/case-study"}>
                            Case Studies
                          </Link>
                        </li>
                        <li>
                          <Link
                            to={process.env.PUBLIC_URL + "/project-grid-one"}
                          >
                            Portfolio
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-sm-3">
                  <div className="footer-widget">
                    <h6 className="widget-title">Support</h6>
                    <div className="footer-menu-link">
                      <ul className="list-unstyled">
                        <li>
                          <Link to={process.env.PUBLIC_URL + "/contact"}>
                            Contact
                          </Link>
                        </li>
                        <li>
                          {" "}
                          <Link to={process.env.PUBLIC_URL + "/privacy-policy"}>
                            Privacy Policy
                          </Link>
                        </li>
                        <li>
                          <Link to={process.env.PUBLIC_URL + "/terms-use"}>
                            Terms of Use
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <div className="row">
            <div className="col-md-6">
              <div className="footer-copyright">
                <span className="copyright-text">
                  © {new Date().getFullYear()}. All rights reserved by{" "}
                  <a href="https://visoan.com/">Visoan</a>. Powered by{" "}
                  <a href="https://oanwave.com/">Oanwave Technology</a>
                </span>
              </div>
            </div>
            <div className="col-md-6">
              <div className="footer-bottom-link">
                <ul className="list-unstyled">
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/privacy-policy"}>
                      Privacy Policy
                    </Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/terms-use"}>
                      Terms of Use
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default FooterOne;
