import React from 'react';
import FooterOne from '../common/footer/FooterOne';
import HeaderOne from '../common/header/HeaderOne';
import CtaLayoutOne from '../component/cta/CtaLayoutOne';
import BreadCrumbOne from '../elements/breadcrumb/BreadCrumbOne';
import ColorSwitcher from '../elements/switcher/ColorSwitcher';
import SEO from '../common/SEO';


const TermsOfUse = () => {

    return (
        <>
            <SEO title="Terms Of Use" />
            <ColorSwitcher />
            <main className="main-wrapper">
                <HeaderOne />
                <BreadCrumbOne 
                title="Terms Of Use"
                page="Terms Of Use"
                />
                <div className="section-padding privacy-policy-area">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-10">
                                <div className="privacy-policy-content">
                                {/* <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto odio dignissim qui blandit praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi.</p>
                                <h4>Nam liber tempor cum soluta</h4>
                                <p>Sed nec ex vitae justo molestie maximus. Sed ut neque sit amet libero rhoncus tempor. Fusce tempor quam libero, varius congue magna tempus vitae. Donec a justo nec elit sagittis sagittis eu a ante. Vivamus rutrum elit odio. Donec gravida id ligula ut faucibus. Aenean convallis ligula orci, ut congue nunc sodales ut. In ultrices elit malesuada velit ornare, eget dictum velit hendrerit. Praesent bibendum blandit lectus, eu congue neque mollis in. Pellentesque metus diam, hendrerit in purus fringilla, accumsan bibendum sapien. Nunc non facilisis sem.
                                </p> */}
                                <h4>About Visoan</h4>
                                <p>Visoan Digital Agency is a digital marketing agency that offers various digital marketing services to businesses. Our services include website development, search engine optimization, social media marketing, email marketing, and more. <a href="mailto:info@visoan.com">info@visoan.com</a> </p>
                                {/* <h4>Nam liber tempor cum soluta</h4>
                                <p>Putamus parum claram, anteposuerit litterarum formas humanitatis per seacula quarta decima et quinta decima. Eodem modo typi, qui nunc nobis videntur parum clari, fiant sollemnes in futurum.</p>
                                <h4>Nam liber tempor cum soluta</h4>
                                <p>Nobis eleifend option congue nihil imperdiet doming id quod mazim placerat facer possim assum. Typi non habent claritatem insitam; est usus legentis in iis qui facit eorum claritatem. Investigationes demonstraverunt lectores legere me lius quod ii legunt saepius. Claritas est etiam processus dynamicus, qui sequitur mutationem consuetudium lectorum.</p>
                                <h4>Mirum est notare quam</h4>
                                <p>Littera gothica, quam nunc putamus parum claram, anteposuerit litterarum formas humanitatis per seacula quarta decima et quinta decima. Eodem modo typi, qui nunc nobis videntur parum clari, fiant sollemnes in futurum.</p>
                                
                                <p>Welcome to Visoan! These Terms of Use govern your use of our website and any associated services. By using our website, you agree to these Terms of Use, so please read them carefully. If you do not agree to these Terms of Use, please do not use our website.</p> */}
<p>Ownership and Use of Content: The contents of this website, including all text, graphics, images, logos, icons, audio and video files, digital downloads, data compilations, and software, are the property of Visoan or its content suppliers and are protected by international copyright laws.</p>
<p>Permitted Use: You may use this website for your personal and non-commercial use only. You may not modify, copy, distribute, transmit, display, perform, reproduce, publish, license, create derivative works from, transfer, or sell any information, software, products or services obtained from this website.</p>
<p>User Conduct: You agree not to use the website for any unlawful or prohibited purpose. You also agree not to use the website in any manner that could damage, disable, overburden, or impair any Visoan server, or interfere with any other party's use and enjoyment of the website.</p>
<p>Disclaimer of Warranties: The website and its content are provided "as is" and without warranties of any kind, either express or implied. Visoan does not warrant that the website will be uninterrupted or error-free, that defects will be corrected, or that the website or the server that makes it available are free of viruses or other harmful components.</p>
<p>Limitation of Liability: In no event shall Visoan be liable for any direct, indirect, incidental, special, punitive, or consequential damages arising out of or in connection with the use or inability to use the website or its content.</p>
<p>Indemnification: You agree to indemnify and hold Visoan harmless from any claim, demand, or damage, including reasonable attorneys' fees, arising out of or related to your use of the website or its content.</p>
<p>Governing Law: These Terms of Use are governed by and construed in accordance with the laws of the jurisdiction in which Visoan is located, without giving effect to any principles of conflicts of law.</p>
<p>Changes to the Terms of Use: Visoan reserves the right to update or modify these Terms of Use at any time without prior notice. Your continued use of the website after any such changes constitutes your acceptance of the new Terms of Use.</p>
<p>If you have any questions about these Terms of Use, please contact us at info@visoan.com.</p>

</div>
                                
                            </div>
                        </div>
                    </div>
                </div>

                <CtaLayoutOne />
            <FooterOne parentClass="" />
            </main>
        </>
    )
}

export default TermsOfUse;