import React from 'react';
import FooterOne from '../common/footer/FooterOne';
import HeaderOne from '../common/header/HeaderOne';
import CtaLayoutOne from '../component/cta/CtaLayoutOne';
import BreadCrumbOne from '../elements/breadcrumb/BreadCrumbOne';
import ColorSwitcher from '../elements/switcher/ColorSwitcher';
import SEO from '../common/SEO';


const PrivacyPolicy = () => {

    return (
        <>
            <SEO title="Privacy Policy" />
            <ColorSwitcher />
            <main className="main-wrapper">
                <HeaderOne />
                <BreadCrumbOne 
                title="Privacy Policy"
                page="Privacy Policy"
                />
                <div className="section-padding privacy-policy-area">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-10">
                                <div className="privacy-policy-content">
                                <div className="section-title">
                                    <h5 className="title">This Privacy policy was published on Febuary 15th, 2023.</h5>
                                </div>
                                <h4>GDPR compliance</h4>
                                <p>Sed nec ex vitae justo molestie maximus. Sed ut neque sit amet libero rhoncus tempor. Fusce tempor quam libero, varius congue magna tempus vitae. Donec a justo nec elit sagittis sagittis eu a ante. Vivamus rutrum elit odio. Donec gravida id ligula ut faucibus. Aenean convallis ligula orci, ut congue nunc sodales ut. In ultrices elit malesuada velit ornare, eget dictum velit hendrerit. Praesent bibendum blandit lectus, eu congue neque mollis in. Pellentesque metus diam, hendrerit in purus fringilla, accumsan bibendum sapien. Nunc non facilisis sem.
                                </p>
                                <h4>About Visoan</h4>
                                <p>Visoan Digital Agency is a digital marketing agency that offers various digital marketing services to businesses. Our services include website development, search engine optimization, social media marketing, email marketing, and more. <a href="mailto:info@visoan.com">info@visoan.com</a> </p>
                                <h4>When we collect personal data about you</h4>
                                <p>We collect personal data about you when you fill out our "Get in touch" form or when you sign up for our newsletter. We also collect personal data about you when you visit our website or use our services.</p>
                                <ul>
                                    <li>Email is a crucial channel in any marketing.</li>
                                    <li>Curious what to say? How to say it?</li>
                                    <li>Whether you’re kicking off a new campaign.</li>
                                </ul>
                                <h4>Why we collect and use personal data</h4>
                                <p className="mb--20">Sed nec ex vitae justo molestie maximus. Sed ut neque sit amet libero rhoncus tempor. Fusce tempor quam libero, varius congue magna tempus vitae. Donec a justo nec elit sagittis sagittis eu a ante. Vivamus rutrum elit odio. Donec gravida id ligula ut faucibus. Aenean convallis ligula orci, ut congue nunc sodales ut. In ultrices elit malesuada velit ornare, eget dictum velit hendrerit. Praesent bibendum blandit lectus, eu congue neque mollis in. Pellentesque metus diam, hendrerit in purus fringilla, accumsan bibendum sapien. Nunc non facilisis sem.</p>
                                <ul>
                                    <li>To provide our services to you.</li>
                                    <li>To communicate with you about our services and promotions.</li>
                                    <li>To improve our services and website.</li>
                                    <li>To analyze website usage and trends.</li>
                                    <li>To comply with legal requirements.</li>
                                </ul>
                                <h4>Type of personal data collected</h4>
                                <p>We collect the following types of personal data:</p>
                                <li>Name and contact information (email, phone number, address).</li>
                                <li>Demographic information (age, gender, location).</li>
                                <li>Website usage information (IP address, browser type, operating system, device type).</li>
                                <li>Payment information (credit card number, expiration date, security code).</li>
                                <h4>Information we collect automatically</h4>
                                <p>We collect certain information automatically when you visit our website, such as your IP address, browser type, operating system, and device type. We use this information to analyze website usage and trends.</p>
                                <h4>The use of cookies and web beacons</h4>
                                <p>We use cookies and web beacons to collect information about your website usage. Cookies are small data files that are stored on your device when you visit our website. Web beacons are small graphics that are embedded in web pages to track website usage. We use cookies and web beacons to analyze website usage and trends, and to personalize your website experience.</p>
                                <p className="mb--20">We may log information using "cookies." Cookies are small data files stored on your hard drive by a website. Cookies help us make our Site and your visit better.</p>
                                <p className="mb--20">may log information using digital images called web beacons on our Site or in our emails.</p>
                                <p>This information is used to make our Site work more efficiently, as well as to provide business and marketing information to the owners of the Site, and to gather such personal data as browser type and operating system, referring page, path through site, domain of ISP, etc. for the purposes of understanding how visitors use our Site. Cookies and similar technologies help us tailor our Site to your personal needs, as well as to detect and prevent security threats and abuse. If used alone, cookies and web beacons do not personally identify you.</p>        
                                <h4>How long we keep your data</h4>
                                <p className="mb--20">We store personal data for as long as we find it necessary to fulfill the purpose for which the personal data was collected, while also considering our need to answer your queries or resolve possible problems. This helps us to comply with legal requirements under applicable laws, to attend to any legal claims/complaints, and for safeguarding purposes.</p>
                                <p>We store personal data for as long as we find it necessary to fulfill the purpose for which the personal data was collected, while also considering our need to answer your queries or resolve possible problems. This helps us to comply with legal requirements under applicable laws, to attend to any legal claims/complaints, and for safeguarding purposes.</p>
                                <h4>Your rights to your personal data</h4>
                                <p>We store personal data for as long as we find it necessary to fulfill the purpose for which the personal data was collected, while also considering our need to answer your queries or resolve possible problems. This helps us to comply with legal requirements under applicable laws, to attend to any legal claims/complaints, and for safeguarding purposes.</p>
                                <ul>
                                <li>The right to access your personal data.</li>
                                <li>The right to correct your personal data.</li>
                                <li>The right to delete your personal data.</li>
                                <li>The right to restrict processing of your personal data.</li>
                                <li>The right to object to processing of your personal data.</li>
                                <li>The right to data portability.</li>
                                </ul>
                                <h4>Hotjar’s privacy policy</h4>
                                <p>We use Hotjar to analyze website usage and trends. Hotjar collects website usage data, such as mouse clicks, mouse movements, and scrolling activity. Hotjar's privacy policy is available at https://www.hotjar.com/legal/policies/privacy/.</p>
                                <h4>Changes to this Privacy Policy</h4>
                                <p>We may update this privacy policy from time to time. If we make any material changes, we will notify you by email or by posting a notice on our website.</p>
                                <p>If you have any questions or concerns about this privacy policy, please contact us at info@visoan.com.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <CtaLayoutOne />
            <FooterOne parentClass="" />
            </main>
        </>
    )
}

export default PrivacyPolicy;