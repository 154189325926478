import React from 'react';
import FormOne from '../contact/FormOne';
import FormTwo from '../contact/FormTwo';


const AboutOne = () => {
    return (
        <section className="section section-padding-equal bg-color-light">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6">
                        <div className="about-us">
                            <div className="section-heading heading-left mb-0">
                                <span className="subtitle">About Us</span>
                                <h2 className="title mb--40">We do design, code &amp; develop.</h2>
                                <p>Welcome to VISOAN, a leading digital marketing agency based in Toronto, Canada. Our team of experienced professionals is dedicated to helping businesses of all sizes achieve their online goals.
At VISOAN, we specialize in creating custom web design and development solutions that are tailored to the unique needs and goals of our clients. We also offer a full range of digital marketing services, including search engine optimization (SEO), social media marketing, and pay-per-click (PPC) advertising, to help businesses reach their target audience and drive qualified traffic to their website.</p>
                                <p>Our team is passionate about staying up to date with the latest trends and technologies in the digital marketing industry, and we pride ourselves on delivering exceptional customer service to all of our clients. We understand the importance of building long-term partnerships with our clients, and we work closely with them to achieve their online goals.</p>
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-5 col-lg-6 offset-xl-1">
                        <div className="contact-form-box">
                            <h3 className="title">Get a free Keystroke quote now</h3>
                           {/* <FormOne /> */}
                           <FormTwo />
                        </div>
                    </div>
                </div>
            </div>
            <ul className="shape-group-6 list-unstyled">
                <li className="shape shape-1"><img src={process.env.PUBLIC_URL + "/images/others/bubble-7.png"} alt="Bubble" /></li>
                <li className="shape shape-2"><img src={process.env.PUBLIC_URL + "/images/others/line-4.png"} alt="line" /></li>
                <li className="shape shape-3"><img src={process.env.PUBLIC_URL + "/images/others/line-5.png"} alt="line" /></li>
            </ul>
        </section>
    )
}

export default AboutOne;