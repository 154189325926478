import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';

const DataContext = React.createContext();

export function useData() {
  return useContext(DataContext);
}

export function DataProvider({ children }) {
  const [allBlogData, setAllBlogData] = useState([]);

  useEffect(() => {
    async function fetchBlogs() {
      try {
        const response = await axios.get('http://localhost:1337/api/blogs');
        setAllBlogData(response.data.data);
      } catch (error) {
        console.error(error);
      }
    }
    fetchBlogs();
  }, []);

  const value = {
    allBlogData,
  };

  return (
    <DataContext.Provider value={value}>
      {children}
    </DataContext.Provider>
  );
}
