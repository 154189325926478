import React, {useRef, useState} from 'react';
import emailjs from '@emailjs/browser';
import Alert from 'react-bootstrap/Alert';

const Result = () => {
    return (
        <Alert variant="success" className="success-msg">
        Your Message has been successfully sent.
        </Alert>
    )
}

const FormTwo = () => {

    const [formData, setFormData] = useState({
        subject: '',
        name: '',
        email: '',
        phone: '',
        message: '',
      });

      const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
      };

      const [ result, showresult ] = useState(false);

      const handleSubmit = (event) => {
        event.preventDefault();
    
        const { subject, name, email, phone, message } = formData;
    
        // send email using SMTPJS
        window.Email.send({
          SecureToken: 'd2828680-16fc-4497-97c6-891921d02eb0',
          To: 'info@visoan.com',
          From: 'contactform@visoan.com',
          Subject: subject,
          Body: `
            <p><b>Name:</b> ${name}</p>
            <p><b>Email:</b> ${email}</p>
            <p><b>Phone:</b> ${phone}</p>
            <p><b>Message:</b> ${message}</p>
          `,
        }).then(showresult(true));
       
        
        // reset form data
        setFormData({
          subject: '',
          name: '',
          email: '',
          phone: '',
          message: '',
        });

        setTimeout(() => {
            showresult(false);
        }, 5000);
      };



    // const form = useRef();

    // //const [ result, showresult ] = useState(false);

    // const sendEmail = (e) => {
    //     e.preventDefault();
    
    //     emailjs.sendForm('service_2uzdhwl', 'template_lbb5ibs', form.current, 'CdTvMae5ERaQRUj6T')
    //       .then((result) => {
    //           console.log(result.text);
    //       }, (error) => {
    //           console.log(error.text);
    //       });
    //       form.current.reset();
    //       showresult(true);
    //   };

    //     setTimeout(() => {
    //         showresult(false);
    //     }, 5000);


    return (
        // <form ref={form} onSubmit={sendEmail} className="axil-contact-form">
        <form onSubmit={handleSubmit} className="axil-contact-form">
        <div className="form-group">
            <label>Name</label>
            {/* <input type="text" className="form-control" name="contact-name" required /> */}
            <input type="text" className="form-control" name="name" value={formData.name} onChange={handleChange} required />
        </div>
        <div className="form-group">
            <label>Email</label>
            {/* <input type="email" className="form-control" name="contact-email" required /> */}
            <input type="email" className="form-control" name="email" value={formData.email} onChange={handleChange} required />
        </div>
        <div className="form-group">
            <label>Phone</label>
            {/* <input type="tel" className="form-control" name="contact-phone" required /> */}
            <input type="tel" className="form-control" name="phone" value={formData.phone} onChange={handleChange} required />
        </div>
        <div className="form-group mb--40">
            <label>How can we help you?</label>
            {/* <textarea className="form-control" name="contact-message" rows="4"></textarea> */}
            <textarea className="form-control" name="message" value={formData.message} onChange={handleChange} rows="4"></textarea>
        </div>
        <div className="form-group">
            <button type="submit" className="axil-btn btn-fill-primary btn-fluid btn-primary" name="submit-btn">Get Pricing Now</button>
        </div>
        <div className="form-group">
            {result ? <Result /> : null}
        </div>

    </form>
    )
}

export default FormTwo;