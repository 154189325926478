import React, { useRef, useState } from 'react';
//import emailjs from '@emailjs/browser';
import Alert from 'react-bootstrap/Alert';
import axios from 'axios';
import Airtable from 'airtable';

const Result = () => {
  return (
    <Alert variant="success" className="success-msg">
      Your Message has been successfully sent.
    </Alert>
  )
}

const DiscoveryForm = () => {


  const [formData, setFormData] = useState({
    subject: '',
    name: '',
    service: '',
    email: '',
    phone: '',
    message: '',
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  };

  const [result, showresult] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const { subject, name, service, email, phone, message } = formData;

    // send email using SMTPJS
    window.Email.send({
      SecureToken: process.env.REACT_APP_SECRET_TOKEN,
      To: 'info@visoan.com',
      From: 'salesfunnel@visoan.com',
      Subject: subject,
      Body: `
            <p><b>Name:</b> ${name}</p>
            <p><b>Service:</b> ${service}</p>
            <p><b>Email:</b> ${email}</p>
            <p><b>Phone:</b> ${phone}</p>
            <p><b>Message:</b> ${message}</p>
          `,
    }).then(showresult(true))



    // send data to mailing list
    const data = new URLSearchParams();
    data.append('api_token', process.env.REACT_APP_EMAILHUP_API_TOKEN);
    data.append('list_uid', process.env.REACT_APP_LIST_UID);
    data.append('EMAIL', email);
    data.append('FIRST_NAME', name);
    data.append('LAST_NAME', '');
    data.append('tag', 'tag1,tag2');

    try {
      const response = await fetch('https://app.emailhup.com/api/v1/subscribers', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
          'accept': 'application/json'
        },
        body: data
      });

      if (!response.ok) {
        throw new Error('Failed to add subscriber to mailing list');
      }

      console.log('Subscriber added to mailing list');
    } catch (error) {
      console.error(error);
    }


    const base = new Airtable({ apiKey: process.env.REACT_APP_AIRTABLE_APIKEY }).base(process.env.REACT_APP_AIRTABLE_BASE);
        try {
          await base(process.env.REACT_APP_AIRTABLE_BASE_NAME).create([
        {
          fields: {
            fullname: name,
            services: service,
            email: email,
            phone: phone,
            message: message,
          },
        },
      ]);

      console.log('Record added to Airtable');
    } catch (error) {
      console.error('Failed to add record to Airtable', error);
    }


    // reset form data
    setFormData({
      subject: '',
      name: '',
      service: '',
      email: '',
      phone: '',
      message: '',
    });

    showresult(true);
    setTimeout(() => {
      showresult(false);
    }, 5000);
};



// const form = useRef();

// //const [ result, showresult ] = useState(false);

// const sendEmail = (e) => {
//     e.preventDefault();

//     emailjs.sendForm('service_2uzdhwl', 'template_lbb5ibs', form.current, 'CdTvMae5ERaQRUj6T')
//       .then((result) => {
//           console.log(result.text);
//       }, (error) => {
//           console.log(error.text);
//       });
//       form.current.reset();
//       showresult(true);
//   };

//     setTimeout(() => {
//         showresult(false);
//     }, 5000);


return (
  // <form ref={form} onSubmit={sendEmail} className="axil-contact-form">
  <form onSubmit={handleSubmit} className="axil-contact-form">
    <div className="form-group">
      <label>Name</label>
      <input type="text" placeholder="Enter Name" className="form-control" name="name" value={formData.name} onChange={handleChange} required />
    </div>
    <div className="form-group">
      <label>Interested Services</label>
      <select
        className="form-control"
        aria-label="Default select example"
        name="service"
        value={formData.service}
        onChange={handleChange}
      >
        <option value="Custom-Website">Custom Website</option>
        <option value="Graphic-Design">Graphic Design</option>
        <option value="Brand-Identity">Brand Identity</option>
        <option value="Social-Media-Management">Social Media Management</option>
        <option value="Backup-Restore">Backup/Restore</option>
        <option value="Other">Other</option>
      </select>
    </div>
    <div className="form-group">
      <label>Email</label>
      {/* <input type="email" className="form-control" name="contact-email" required /> */}
      <input type="email" placeholder="Enter Email" className="form-control" name="email" value={formData.email} onChange={handleChange} required />
    </div>
    <div className="form-group">
      <label>Phone</label>
      {/* <input type="tel" className="form-control" name="contact-phone" required /> */}
      <input type="tel" placeholder="Enter Phone Number" className="form-control" name="phone" value={formData.phone} onChange={handleChange} required />
    </div>
    <div className="form-group mb--40">
      <label>How can we help you?</label>
      <textarea className="form-control" placeholder="Please describe the project" name="message" value={formData.message} onChange={handleChange} rows="4"></textarea>
    </div>
    <div className="form-group d-flex justify-content-center">
      <button type="submit" className="axil-btn btn-fill-primary btn-fluid btn-primary w-50" name="submit-btn">Book Your Consultation</button>
    </div>
    <div className="form-group">
      {result ? <Result /> : null}
    </div>

  </form>
)
}

export default DiscoveryForm;