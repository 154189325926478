import React from 'react';
import SectionTitle from '../../elements/section-title/SectionTitle';

const Datas = [
    {
        id: 1,
        title: "Exceed clients’ and colleagues’ expectations",
        para: "At Visoan, we strive to exceed expectations by delivering exceptional services and exceeding customer satisfaction."
    },
    {
        id: 2,
        title: "Take ownership and question the status quo in a constructive manner",
        para: "Our team is always questioning the status quo and looking for innovative solutions to improve our processes and services"
    },
    {
        id: 3,
        title: "Be brave, curious and experiment – learn from all successes and failures",
        para: "At Visoan, we encourage our team to take risks, experiment, and learn from their experiences to drive growth and success."
    },
    {
        id: 4,
        title: "Act in a way that makes all of us proud",
        para: "Our team is dedicated to acting in a manner that makes us proud, upholding the highest standards of professionalism and integrity."
    },
    {
        id: 5,
        title: "Build an inclusive, transparent and socially responsible culture",
        para: "We believe in creating an inclusive, transparent, and socially responsible culture, where all employees feel valued and respected."
    }
]




const AboutThree = () => {
    return (
        <div className="section section-padding bg-color-dark pb--80 pb_lg--40 pb_md--20">
            <div className="container">
                <SectionTitle 
                    subtitle="Our Valus"
                    title="Why should you work with us?"
                    description="Our team takes the time to understand your objectives, target audience, and competition to develop a customized strategy that works for you."
                    textAlignment="heading-left heading-light-left mb--100"
                    textColor=""
                />

                <div className="row">
                    {Datas.map((data) => (
                        <div className="col-lg-4" key={data.id}>
                            <div className="about-quality">
                                <h3 className="sl-number">{data.id}</h3>
                                <h5 className="title">{data.title}</h5>
                                <p>{data.para}</p>
                            </div>
                        </div>
                    ))}

                </div>
            </div>
            <ul className="list-unstyled shape-group-10">
                <li className="shape shape-1"><img src={process.env.PUBLIC_URL + "/images/others/circle-1.png"} alt="Circle" /></li>
                <li className="shape shape-2"><img src={process.env.PUBLIC_URL + "/images/others/line-3.png"} alt="Circle" /></li>
                <li className="shape shape-3"><img src={process.env.PUBLIC_URL + "/images/others/bubble-5.png"} alt="Circle" /></li>
            </ul>
        </div>
    )
}

export default AboutThree;